import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { KioskMembersComponent } from '../components/kiosk/kiosk-members/kiosk-members.component';
import { KioskScheduleComponent } from '../components/kiosk/kiosk-schedule/kiosk-schedule.component';
import { KioskTriviaComponent } from '../components/kiosk/kiosk-trivia/kiosk-trivia.component';
import { KioskComponent } from '../components/kiosk/kiosk.component';
import { AuthGuard } from '../guards/auth.guard';
import { AuthService } from '../services/auth.service';

const routes: Routes = [
	{
		path: '',
		component: KioskComponent,
		children: [
			{
				path: '',
				redirectTo: 'members',
				pathMatch: 'full',
			},
			{
				path: 'members',
				component: KioskMembersComponent,
				canActivate: [AuthGuard],
			},
			{
				path: 'schedules',
				component: KioskScheduleComponent,
				canActivate: [AuthGuard],
			},
			{
				path: 'trivia',
				component: KioskTriviaComponent,
				canActivate: [AuthGuard],
			},
		],
	},
];

@NgModule({
	exports: [RouterModule],
	imports: [RouterModule.forChild(routes)],
	providers: [AuthService, AuthGuard],
})
export class KioskRoutingModule {}
