import { Component, Input, OnInit } from '@angular/core';
import { CustomFunctions, Email } from '../../../../models/global.model';
import { TrivaQuestionHistoryData, TriviaQuestion } from '../../../../models/interface.model';
import { FirebaseFunctionsService } from '../../../../services/firebase.functions';
import { FirebaseService } from '../../../../services/firebase.service';
import { KioskComponent } from '../../kiosk.component';

@Component({
	selector: 'app-kiosk-triva-answer',
	templateUrl: './kiosk-triva-answer.component.html',
	styleUrls: ['./kiosk-triva-answer.component.css'],
})
export class KioskTrivaAnswerComponent implements OnInit {
	// These properties are public because they are used in the view
	@Input() question!: TriviaQuestion;
	public answerCorrect!: boolean;
	public timer!: number;

	// These properties are private because they are only used in the component
	private historyData: TrivaQuestionHistoryData = {
		givenAnswer: '',
		correctAnswer: '',
		correct: false,
		cumulativeCorrect: 0,
		lastCorrectDate: '',
		lastDate: '',
		question: '',
	};

	constructor(public kioskComponent: KioskComponent, private firebaseService: FirebaseService, private firebaseFunctions: FirebaseFunctionsService) {}

	ngOnInit() {
		// get today

		const trivaDate = CustomFunctions.midnight();
		// set question data last date
		this.firebaseService.serviceQuestionData.mas.triviaQuestionData.lastDate = trivaDate;
		this.historyData.lastDate = trivaDate;
		this.historyData.lastCorrectDate = this.firebaseService.serviceQuestionData.mas.triviaQuestionData.lastCorrectDate;
		this.historyData.question = this.question.QUESTION;

		// if a tiger question add the highlight to the question.
		if (this.question.HIGHLIGHT) {
			this.historyData.question = `${this.question.QUESTION} ${this.question.HIGHLIGHT}`;
		}

		this.historyData.givenAnswer = this.kioskComponent.givenAnswer;
		this.historyData.correctAnswer = this.question.ANSWER;

		if (this.kioskComponent.givenAnswer === this.question.ANSWER) {
			// set question data last correct date
			this.firebaseService.serviceQuestionData.mas.triviaQuestionData.lastCorrectDate = trivaDate;
			this.historyData.lastCorrectDate = trivaDate;

			// for the view
			this.answerCorrect = true;
			this.historyData.correct = true;

			// set the cumulative correct
			this.firebaseService.serviceQuestionData.mas.triviaQuestionData.cumulativeCorrect++;
			this.historyData.cumulativeCorrect = this.firebaseService.serviceQuestionData.mas.triviaQuestionData.cumulativeCorrect;

			// if evenly divided by 15 send email
			if (this.answerCorrect && this.firebaseService.serviceQuestionData.mas.triviaQuestionData.cumulativeCorrect % 15 === 0) {
				const email: Email = {
					sender: 'gh@yongsa.net',
					to: 'taraharner@yongsa.net',
					subject: 'Trivia Red Stars',
					text: `${this.firebaseService.memberName} has earned five red stars for answering ${
						this.firebaseService.serviceQuestionData.mas.triviaQuestionData.cumulativeCorrect
					} trivia question correctly. (${this.firebaseService.serviceQuestionData.mas.triviaQuestionData.cumulativeCorrect % 15})`,
				};
				this.firebaseFunctions.sendEmail(email);
			}
		} else {
			this.firebaseService.serviceQuestionData.mas.triviaQuestionData.lastDate = trivaDate;
			this.answerCorrect = false;
		}

		const col = `mas-accounts/${this.firebaseService.memberId}/mas-accounts-trivia`;
		const historyId = CustomFunctions.midnight();

		// add history
		this.firebaseService.documentSet(col, historyId, this.historyData);

		// update account
		this.firebaseService.documentSet('mas-accounts', this.firebaseService.memberId, this.firebaseService.serviceQuestionData);

		this.timer = 10;
		const t = setInterval(() => {
			this.timer;
			this.timer--;
			if (this.timer === 0) {
				clearInterval(t);
				this.onDone();
			}
		}, 1000);
	}

	onDone(): void {
		this.timer = 0;
		this.answerCorrect = false;
		this.kioskComponent.setPill(0);
	}
}
