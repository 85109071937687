import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { ButtonModule } from 'primeng/button';
import { MegaMenuModule } from 'primeng/megamenu';
import { KioskMembersComponent } from '../components/kiosk/kiosk-members/kiosk-members.component';
import { KioskScheduleComponent } from '../components/kiosk/kiosk-schedule/kiosk-schedule.component';
import { KioskTrivaAnswerComponent } from '../components/kiosk/kiosk-trivia/kiosk-triva-answer/kiosk-triva-answer.component';
import { KioskTrivaQuestionComponent } from '../components/kiosk/kiosk-trivia/kiosk-triva-question/kiosk-triva-question.component';
import { KioskTrivaTigersComponent } from '../components/kiosk/kiosk-trivia/kiosk-triva-tigers/kiosk-triva-tigers.component';
import { KioskTriviaComponent } from '../components/kiosk/kiosk-trivia/kiosk-trivia.component';
import { KioskComponent } from '../components/kiosk/kiosk.component';
import { KioskRoutingModule } from '../routing/kiosk.routing';

@NgModule({
	exports: [MatCardModule],
	declarations: [KioskComponent, KioskMembersComponent, KioskScheduleComponent, KioskTrivaAnswerComponent, KioskTrivaQuestionComponent, KioskTrivaTigersComponent, KioskTriviaComponent],
	imports: [CommonModule, KioskRoutingModule, FormsModule, ButtonModule, FormsModule, MegaMenuModule],
})
export class KioskModule {}
