<!--
  *
  * view of buttons with first character of names
  *
-->
<div id="membercontainer" *ngIf="letter">
  <button pButton type="button" [label]="i" *ngFor="let i of studentName" (click)="getNames(i)"></button>
</div>
<!--
  *
  * view of names that match first character
  *
-->
<div class="p-d-flex p-flex-column">
  <div *ngIf="!letter">
    <button pButton type="button" [label]="i.names.givenName +' ' + i.names.familyName" *ngFor=" let i of studentName" (click)="getMemberPrograms(i)"></button>
    <!--
  *
  * if the closed button is selected start over at get accounts.
  *
    -->
    <div>
      <button pButton class="p-button-secondary" type="button" label="Close" (click)="getAccounts()"></button>
    </div>
  </div>
</div>