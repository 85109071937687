import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FirebaseService } from 'src/app/services/firebase.service';

@Component({
	selector: 'app-kiosk',
	templateUrl: './kiosk.component.html',
	styleUrls: ['./kiosk.component.css'],
})
export class KioskComponent {
	// These properties are public because they are used in the view
	public sPill = 0;
	public isTigerQuestion = false;
	public hasAnswer = false;
	public givenAnswer!: string;
	public correctAnswer!: string;

	constructor(private router: Router, public firebaseService: FirebaseService) {}

	setPill(value: number, data?: {}): void {
		this.sPill = value;

		if (this.sPill === 1) {
			this.router.navigateByUrl('/schedules', { state: data });
		} else if (this.sPill === 2) {
			this.router.navigateByUrl('/trivia', { state: data });
		} else {
			this.hasAnswer = false;
			this.givenAnswer = '';
			this.firebaseService.resetServiceQuestionData();
			this.router.navigate(['/members']);
		}
	}
}
