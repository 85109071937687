import { Component, Input, OnInit } from '@angular/core';
import { TriviaQuestion } from '../../../../models/interface.model';
import { KioskComponent } from '../../kiosk.component';

@Component({
	selector: 'app-kiosk-triva-question',
	templateUrl: './kiosk-triva-question.component.html',
	styleUrls: ['./kiosk-triva-question.component.css'],
})
export class KioskTrivaQuestionComponent implements OnInit {
	// These properties are public because they are used in the view
	@Input() question!: TriviaQuestion;

	constructor(private kioskComponent: KioskComponent) {}

	ngOnInit() {}

	onButtonClick(answer: string): void {
		this.kioskComponent.correctAnswer = this.question.ANSWER;
		this.kioskComponent.hasAnswer = true;
		this.kioskComponent.givenAnswer = answer;
	}

	onClose() {
		this.kioskComponent.setPill(0);
	}
}
