import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Account } from '../../../models/accounts.model';
import { FirebaseService } from '../../../services/firebase.service';
import { KioskComponent } from '../kiosk.component';

@Component({
	selector: 'app-kiosk-members',
	templateUrl: './kiosk-members.component.html',
	styleUrls: ['./kiosk-members.component.css'],
})
export class KioskMembersComponent implements OnInit, OnDestroy {
	private accounts: Account[] = [];
	private accountsSubscription!: Subscription;

	// These properties are public because they are used in the view
	public studentName: any[] = [];
	public letter!: boolean;

	constructor(private firebaseService: FirebaseService, private kioskComponent: KioskComponent) {}

	ngOnInit(): void {
		this.getAccounts();
	}

	getAccounts() {
		this.letter = true;
		this.accountsSubscription = this.firebaseService.getAccounts().subscribe(
			accounts => {
				this.accounts = accounts;
				// get first character of names for buttons
				const button = this.accounts.map(m => m.names.givenName.charAt(0));
				this.studentName = button.filter((item, index) => {
					return button.indexOf(item) >= index;
				});
			},
			error => {
				console.log(error.message);
			}
		);
	}

	getNames(name: any): void {
		try {
			this.studentName = [];
			this.accounts.map(m => {
				if (m.names.givenName.indexOf(name) === 0) this.studentName.push(m);
			});
			this.letter = false;
		} catch (error: any) {
			console.log(error.message);
		}
	}

	async getMemberPrograms(member: Account) {
		let programs: string[] = [];

		await this.firebaseService.getMemberPrograms(member).then(results => {
			results.forEach(program => {
				programs.push(program.data().name);
			});
		});

		if (typeof member.mas.triviaQuestionData !== 'undefined') {
			// set trivia question data
			this.firebaseService.serviceQuestionData.mas.triviaQuestionData.lastDate = member.mas.triviaQuestionData.lastDate;
			this.firebaseService.serviceQuestionData.mas.triviaQuestionData.cumulativeCorrect = member.mas.triviaQuestionData.cumulativeCorrect;
			this.firebaseService.serviceQuestionData.mas.triviaQuestionData.lastCorrectDate = member.mas.triviaQuestionData.lastCorrectDate;
		}

		this.firebaseService.memberId = member.id;
		this.firebaseService.memberName = `${member.names.givenName} ${member.names.familyName}`;

		const billing: string = member.mas.linkedAccounts
			?.filter(f => f.type === 'parent')
			.map(m => m.accountId)
			.pop() as string;

		this.firebaseService.billingEmail = await this.firebaseService.getBillingEmail(billing).then(email => email);

		this.kioskComponent.setPill(1, programs);
	}

	ngOnDestroy(): void {
		if (this.accountsSubscription) this.accountsSubscription.unsubscribe();
	}
}
