import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from '../components/login/login.component';
import { AuthGuard } from '../guards/auth.guard';
import { AuthService } from '../services/auth.service';

const routes: Routes = [{ path: 'login', component: LoginComponent }];

@NgModule({
	exports: [RouterModule],
	imports: [RouterModule.forRoot(routes)],
	providers: [AuthService, AuthGuard],
})
export class AppRoutingModule {}
