<div *ngIf="question">
    <div style="margin-bottom: 1em;"> <span>{{question.QUESTION}}</span> </div>
    <div class="grid p-justify-center">
        <div class="row" *ngFor="let answer of question.ANSWERS; let i=index" (click)="onButtonClick(answer)">
            <button pButton type="button" [label]="answer" (click)="onButtonClick(answer)"></button>
        </div>
    </div>
    <div class="grid p-justify-center">
        <div class="row">
            <button pButton class="p-button-secondary" type="button" [label]="'Close'" (click)="onClose()"></button>
        </div>
    </div>
</div>