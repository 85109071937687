import { Component, Input } from '@angular/core';
import { TriviaQuestion } from '../../../../models/interface.model';
import { KioskComponent } from '../../kiosk.component';

interface Icons {
	[key: string]: string | number;
}

@Component({
	selector: 'app-kiosk-triva-tigers',
	templateUrl: './kiosk-triva-tigers.component.html',
	styleUrls: ['./kiosk-triva-tigers.component.css'],
})
export class KioskTrivaTigersComponent {
	// These properties are private because they are only used in the component
	@Input() question!: TriviaQuestion;

	public icons: Icons = {
		ara: 'https://firebasestorage.googleapis.com/v0/b/yongsa.net/o/tigers%2Fara.png?alt=media&token=f145ab5a-bacc-40b8-bd64-3cc9e51b46be',
		baeoh: 'https://firebasestorage.googleapis.com/v0/b/yongsa.net/o/tigers%2Fbaeoh.png?alt=media&token=080fcd0f-ac60-47d1-9809-2f216a83082e',
		cheeri: 'https://firebasestorage.googleapis.com/v0/b/yongsa.net/o/tigers%2Fcheeri.png?alt=media&token=455a54b3-5bd3-4e78-a7a6-5b4716850fd0',
		raon: 'https://firebasestorage.googleapis.com/v0/b/yongsa.net/o/tigers%2Fraon.png?alt=media&token=e1fe9fde-50a7-4eef-bb46-4ec14a322685',
		suri: 'https://firebasestorage.googleapis.com/v0/b/yongsa.net/o/tigers%2Fsuri.png?alt=media&token=f33a2568-3d4a-4f7a-9ba5-12df5ef411aa',
		choa: 'https://firebasestorage.googleapis.com/v0/b/yongsa.net/o/tigers%2Fchoa.png?alt=media&token=3177bfde-4c15-440b-89a1-25948c77e05c',
		mir: 'https://firebasestorage.googleapis.com/v0/b/yongsa.net/o/tigers%2Fmir.png?alt=media&token=10741bd6-9538-44f2-aafc-6ce6efb1a127',
		narsha: 'https://firebasestorage.googleapis.com/v0/b/yongsa.net/o/tigers%2Fnarsha.png?alt=media&token=62db6556-c049-4a86-9d86-31df4e30ce3c',
		baron: 'https://firebasestorage.googleapis.com/v0/b/yongsa.net/o/tigers%2Fbaron.png?alt=media&token=8717c402-0af2-4ead-a29b-6f95b163e5e0st',
	};

	constructor(private kioskComponent: KioskComponent) {}

	onButtonClick(answer: string): void {
		this.kioskComponent.correctAnswer = this.question.ANSWER;
		this.kioskComponent.hasAnswer = true;
		this.kioskComponent.givenAnswer = answer;
	}

	onClose() {
		this.kioskComponent.setPill(0);
	}
}
