// @ts-nocheck
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FirebaseService } from 'src/app/services/firebase.service';
import { TriviaQuestion } from '../../../models/interface.model';
import { KioskComponent } from '../kiosk.component';
@Component({
	selector: 'app-kiosk-trivia',
	templateUrl: './kiosk-trivia.component.html',
	styleUrls: ['./kiosk-trivia.component.css'],
})
export class KioskTriviaComponent implements OnInit, OnDestroy {
	// These properties are private because they are only used in the component
	private programNames: string[] = [];

	// These properties are public because they are used in the view
	public question: TriviaQuestion;

	constructor(private router: Router, public kioskComponent: KioskComponent, private firebaseService: FirebaseService) {
		if (this.router.getCurrentNavigation().extras.state) {
			this.programNames = this.router.getCurrentNavigation().extras.state;
		} else {
			this.kioskComponent.setPill(0);
		}
	}

	ngOnInit() {
		this.getTriviaQuestions();
	}

	getTriviaQuestions() {
		this.firebaseService
			.getTrivia(this.programNames)
			.then((q: TriviaQuestion) => {
				this.programNames.includes('Tigers') ? (this.kioskComponent.isTigerQuestion = true) : (this.kioskComponent.isTigerQuestion = false);
				this.question = this.firebaseService.addTrivaAnswers(q);
			})
			.catch(error => console.log(error));
	}
}
