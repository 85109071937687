import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MegaMenuItem } from 'primeng/api';
import { AuthService } from '../../services/auth.service';

@Component({
	selector: 'app-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.css'],
})
export class NavbarComponent implements OnInit {
	// These properties are public because they are used in the view
	public isLoggedIn = true;
	public items: MegaMenuItem[] = [];

	constructor(private authService: AuthService, private router: Router) {}

	ngOnInit() {
		this.authService.getAuth().subscribe(auth => {
			if (auth) {
				const userDomain = auth.email?.replace(/.*@/, '');
				if (userDomain !== 'yongsa.net') {
					this.authService.logout();
				} else {
					this.isLoggedIn = true;
					// call to create MegaMenuItem[]
					this.setItems();
				}
			} else {
				this.isLoggedIn = false;
				this.router.navigate(['/login']);
			}
		});
	}

	// navigate to the selected MegaMenuItem[] index
	onMenuClick(e: any): void {
		this.items.forEach((item, index) => {
			if (item.id === e.item.id) {
				console.log(index);
				this.router.navigate([`/${e.item.id}`]);
			}
		});
	}

	/***********************************************************************************************************************
	 * @name ngOnInit
	 * @memberof NavbarComponent
	 * @author Greg Harner
	 * @description This function is using for user's logout and redirect to login page.
	 * @argument {}
	 * @method authService.logout()
	 * @method router.navigate(['/login'])
	 * @return void
	 * @todo update UML
	 * @link
	 ***********************************************************************************************************************/
	onLogoutClick() {
		this.setItems(false);
		this.authService.logout();
		this.router.navigate(['/login']);
	}

	closeItem(event: any, index: any) {
		this.items = this.items.filter((item, i) => i !== index);
		event.preventDefault();
	}

	// creates MegaMenuItem[]
	setItems(_visibility = true) {
		this.items = [
			{
				id: 'logout',
				label: 'Logout',
				icon: 'pi pi-fw pi-sign-in',
				visible: _visibility,
				command: () => {
					this.onLogoutClick();
				},
			},
		];
	}
}
