import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firebase } from '../../environments/environment';
import { Email } from '../models/global.model';
import { AuthService } from '../services/auth.service';
@Injectable({
	providedIn: 'root',
})
export class FirebaseFunctionsService {
	constructor(private http: HttpClient, private authService: AuthService) {}

	sendEmail(email: any) {
		this.authService.getAuth().subscribe(
			(authObject: any) => {
				authObject.getIdToken().then((token: any) => {
					this.sendEmailPost(token, email);
				});
			},
			error => console.log(error)
		);
	}

	sendEmailPost(token: any, email: Email) {
		const message = {
			sender: email.sender,
			to: email.to,
			subject: email.subject,
			text: email.text,
		};

		const headers = new HttpHeaders({ token: token });
		return this.http.post(`${firebase.apiEndpoint}masEmail/`, message, { headers, responseType: 'text' }).toPromise();
	}
}
