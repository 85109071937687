import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CustomFunctions, Email } from 'src/app/models/global.model';
import { Schedule } from 'src/app/models/programs.model';
import { firebase } from '../../../../environments/environment';
import { FirebaseFunctionsService } from '../../../services/firebase.functions';
import { FirebaseService } from '../../../services/firebase.service';
import { KioskComponent } from '../kiosk.component';

@Component({
	selector: 'app-schedule',
	templateUrl: './kiosk-schedule.component.html',
	styleUrls: ['./kiosk-schedule.component.css'],
})
export class KioskScheduleComponent implements OnInit {
	// These properties are private because they are only used in the component
	private programNames: string[] = [];

	// These properties are public because they are used in the view
	public schedules: Schedule[] = [];

	constructor(
		private kioskComponent: KioskComponent,
		private router: Router,
		public firebaseService: FirebaseService,
		private firebaseFunctions: FirebaseFunctionsService
	) {
		if (this.router.getCurrentNavigation()?.extras.state) {
			this.programNames = this.router.getCurrentNavigation()?.extras.state as string[];
			this.getSchedules();
		} else {
			this.kioskComponent.setPill(0);
		}
	}

	ngOnInit(): void {}

	async getSchedules(): Promise<void> {
		const reserveNames: string[] = await this.getReserveProgramNames();

		if (this.programNames) {
			const filteredSchedules: Schedule[] = [];
			const startDate = CustomFunctions.midnight();
			const endDate = CustomFunctions.midnight(firebase.offSetDays);

			this.firebaseService.getSchedules(this.programNames, startDate, endDate).then(async schedules => {
				schedules.docs.forEach(doc => {
					const data = doc.data();

					// Does this class require a reservation?
					const reserveCheck = reserveNames.indexOf(data.summary) !== -1 ? true : false;

					// Has the member made a reservation if the class requires a reservation?
					let indexCheck = data.membersReserved?.indexOf(this.firebaseService.memberId);

					// If the reservation array is undefined
					if (typeof indexCheck === 'undefined') indexCheck = -1;

					// Anything other than a -1 means I found the member in the reservation list.
					if (indexCheck !== -1) {
						data.viewSettings = {
							buttonDisabled: false,
							memberReservedIndex: indexCheck,
							optionReserve: reserveCheck,
						};
					} else {
						data.viewSettings = {
							// disable button if reservation does not exist but is required
							buttonDisabled: reserveCheck ? true : false,
							memberReservedIndex: -1,
							optionReserve: reserveCheck,
						};
					}

					// this is a data cache and will change accordingly
					filteredSchedules.push(data);
				});

				this.schedules = filteredSchedules.filter(f => f.end.dateTime > CustomFunctions.localClientTime());
			});
		}
	}

	getReserveProgramNames() {
		return this.firebaseService.getPrograms().then(program => program.docs.filter(f => f.data().optionReserve === true).map(m => m.data().name));
	}

	joinClassClick(i: Schedule) {
		const memberId = this.firebaseService.memberId;

		// cycleName cannot be undefined
		if (!i.cycleName) i.cycleName = '';

		const currentCycleData = {
			cycleName: i.cycleName,
			scheduleId: i.id,
			scheduleName: i.summary,
		};

		if (i.viewSettings?.optionReserve && i.viewSettings.memberReservedIndex! !== -1) {
			i.membersNames![i.viewSettings.memberReservedIndex!] = this.firebaseService.memberName;
			i.membersAttended![i.viewSettings.memberReservedIndex!] = this.firebaseService.memberId;
		} else {
			if (i.membersNames) {
				i.membersNames.push(this.firebaseService.memberName);
			} else {
				i.membersNames = [this.firebaseService.memberName];
			}

			if (i.membersAttended) {
				i.membersAttended.push(memberId);
			} else {
				i.membersAttended = [memberId];
			}
		}

		const col = `mas-accounts/${memberId}/mas-accounts-attendance`;
		this.firebaseService.documentSet(col, i.id, currentCycleData);

		this.firebaseService.documentSet('mas-schedules', i.id, i);

		if (i.summary === 'YAS') this.sendYASEmail();
	}

	sendYASEmail() {
		if (this.firebaseService.billingEmail) {
			const email: Email = {
				sender: 'taraharner@yongsa.net',
				to: `${this.firebaseService.billingEmail}, accounts@yongsa.net`,
				subject: `${this.firebaseService.memberName} has arrived at YAS`,
				text: `For parents in our Yongsa After School (YAS), we provide a security camera video feed so that you can look in on your child and feel confident they safely made it from school to our studio. The feed is only for YAS. For security reasons,the login and password are separate from your my.yongsa.net login and password. Please do not share the login and password with anyone else. The password will change at the beginning of every cycle. http://yongsa.gvdip.com/.
				
				Login: yas 
				
				Password: AraTheTurtle.`,
			};

			this.firebaseFunctions.sendEmail(email);
		}
	}

	leaveClassClick(i: Schedule) {
		const memberId = this.firebaseService.memberId;
		const idx: number = i.membersAttended!.indexOf(memberId);

		const col = `mas-accounts/${memberId}/mas-accounts-attendance`;
		this.firebaseService.documentDelete(col, i.id);

		if (i.viewSettings?.optionReserve === false) {
			i.membersAttended!.splice(idx, 1);
			i.membersNames!.splice(idx, 1);
		} else {
			i.membersAttended![idx] = 'reserved';
		}

		this.firebaseService.documentSet('mas-schedules', i.id, i);
	}

	onDoneClick(): void {
		// if member has already answered a question today then go to the kiosk-members view
		this.firebaseService.serviceQuestionData.mas.triviaQuestionData.lastDate !== CustomFunctions.midnight()
			? this.kioskComponent.setPill(2, this.programNames)
			: this.kioskComponent.setPill(0);
	}

	onCloseClick() {
		this.kioskComponent.setPill(0);
	}
}
