import { Injectable } from '@angular/core';
import {
	collection,
	collectionChanges,
	CollectionReference,
	collectionSnapshots,
	deleteDoc,
	doc,
	Firestore,
	getDoc,
	getDocs,
	orderBy,
	query,
	setDoc,
	where,
} from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { Account } from '../models/accounts.model';
import { CustomFunctions } from '../models/global.model';
import { TrivaQuestionData, TriviaQuestion } from '../models/interface.model';
import { Program, Schedule } from '../models/programs.model';

@Injectable({
	providedIn: 'root',
})
export class FirebaseService {
	public serviceQuestionData: TrivaQuestionData = {
		mas: { triviaQuestionData: { cumulativeCorrect: 0, lastCorrectDate: '', lastDate: '' } },
	};
	public memberId!: string;
	public memberName!: string;

	public billingEmail!: string | null;

	constructor(private afs: Firestore) {}

	resetServiceQuestionData() {
		// reset lastDate for next member
		this.serviceQuestionData.mas.triviaQuestionData.lastDate = '';
		this.serviceQuestionData.mas.triviaQuestionData.lastCorrectDate = '';
		this.serviceQuestionData.mas.triviaQuestionData.cumulativeCorrect = 0;
	}

	getAccounts() {
		return collectionSnapshots<Account>(
			query<Account>(
				collection(this.afs, 'mas-accounts') as CollectionReference<Account>,
				where('mas.accountSettings.status', 'in', ['Active', 'Pending', 'Trial']),
				where('mas.accountSettings.member', '==', 'true'),
				orderBy('names.givenName')
			)
		).pipe(
			map(accountChanges => {
				return accountChanges.map(accounts => {
					const account = accounts.data() as Account;
					account.id = accounts.id;
					return account;
				});
			})
		);
	}

	getSchedules(programs: string[], startDate: string, endDate: string) {
		const q = query<Schedule>(
			collection(this.afs, 'mas-schedules') as CollectionReference<Schedule>,
			where('summary', 'in', programs),
			where('start.dateTime', '>', startDate),
			where('start.dateTime', '<', endDate),
			orderBy('start.dateTime')
		);

		return getDocs(q);
	}

	async getTrivia(programNames: string[]) {
		let id = 'standard';

		if (typeof programNames !== 'undefined' && programNames.includes('Tigers')) id = 'tigers';

		const docRef = doc(this.afs, 'mas-trivia', id);
		const docSnap = await getDoc(docRef);

		return docSnap.data();
	}

	addTrivaAnswers(data: any): TriviaQuestion {
		// get a random trivia question
		const random = Math.floor(Math.random() * (data.QUESTIONS.length - 1));
		const question = data.QUESTIONS[random];

		const answerArray = [];

		// get all questions of the same type as random question to construct wrong answer array
		const wrongAnswerQuestions = data.QUESTIONS.filter((f: any) => f.TYPE === question.TYPE && f.ANSWER !== question.ANSWER);

		// get only the answers
		const wrongAnswers = wrongAnswerQuestions.map((a: any) => a.ANSWER);

		// make answers unique
		const uniqueAnswers = [...new Set(wrongAnswers)];

		// add correct answer to answer array
		answerArray.push(question.ANSWER);

		// add wrong answers to array
		let i = 0;
		while (i < 3) {
			const randomWrongAnswer = Math.floor(Math.random() * (uniqueAnswers.length - 1));
			answerArray.push(uniqueAnswers[randomWrongAnswer]);
			const removed = uniqueAnswers.splice(randomWrongAnswer, 1);
			i++;
			if (uniqueAnswers.length === 0) i = 3;
		}

		// shuffle the answer array
		question.ANSWERS = CustomFunctions.shuffle(answerArray as []);
		return question;
	}

	getPrograms() {
		const q = query<Program>(collection(this.afs, 'mas-programs') as CollectionReference<Program>);

		return getDocs(q);
	}

	getMemberPrograms(member: Account) {
		const membership = member.memberships?.map(m => m.contactGroupId);

		const q = query<Program>(collection(this.afs, 'mas-programs') as CollectionReference<Program>, where('id', 'in', membership));

		return getDocs(q);
	}

	documentSet(col: string, id: string, payload: {}) {
		const schedule = doc(this.afs, col, id);
		setDoc(schedule, payload, { merge: true });
	}

	documentDelete(col: string, id: string) {
		const data = doc(this.afs, col, id);
		deleteDoc(data);
	}

	async getSchedule(id: string) {
		const docRef = doc(this.afs, 'mas-schedules', id);
		const docSnap = await getDoc(docRef);

		return docSnap.data() as Schedule;
	}

	async getBillingEmail(id: string) {
		const docRef = doc(this.afs, 'mas-accounts', id);
		const docSnap = await getDoc(docRef);

		const billing = docSnap.data() as Account;
		if (billing.emailAddresses?.value) {
			return billing.emailAddresses?.value;
		} else {
			return null;
		}
	}
}
