// declarations: The classes that belong to this module and are related to views.
// There are three classes in Angular that can contain views: components, directives
// and pipes. exports: The classes that should be accessible to other modules components.
// imports: Modules whose classes are needed by the components of this module.
// providers: Services present in one of the modules which are going to be used in the
// other modules or components. Once a service is included in the providers, it becomes
// accessible in all parts of that application. bootstrap: The root component which is
// the main view of the application. Only the root module has this property and it
// indicates the component that’s gonna be bootstrapped. entryComponents: An entry
// component is any component that Angular loads imperatively, (which means you’re
// not referencing it in the template), by type.

// imports (Packages)
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getFunctions, provideFunctions } from '@angular/fire/functions';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { BrowserModule } from '@angular/platform-browser';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { MegaMenuModule } from 'primeng/megamenu';
import { firebase } from '../../environments/environment';
import { AppComponent } from '../app.component';
import { LoginComponent } from '../components/login/login.component';
import { NavbarComponent } from '../components/navbar/navbar.component';
import { AuthService } from '../services/auth.service';
import { AppRoutingModule } from '../routing/app.routing';
import { KioskModule } from './kiosk.module';

@NgModule({
	exports: [MatCardModule],
	declarations: [AppComponent, LoginComponent, NavbarComponent],
	imports: [
		AppRoutingModule,
		BrowserModule,
		ButtonModule,
		CardModule,
		FormsModule,
		HttpClientModule,
		KioskModule,
		MegaMenuModule,
		ReactiveFormsModule,
		provideAuth(() => getAuth()),
		provideFirebaseApp(() => initializeApp(firebase.Cfg)),
		provideFirestore(() => getFirestore()),
		provideFunctions(() => getFunctions()),
		provideMessaging(() => getMessaging()),
		provideStorage(() => getStorage()),
	],
	providers: [AuthService],
	bootstrap: [AppComponent],
})
export class AppModule {}
