<div *ngIf="schedules.length > 0; else loading">
  <span>Schedule for {{this.firebaseService.memberName}}</span>
  <table>
    <thead>
      <tr>
        <th style="min-width: 102px;">Date</th>
        <th style="min-width: 102px;">Start Time</th>
        <th style="min-width: 102px;">End Time</th>
        <th style="min-width: 102px;">Summary</th>
        <th style="min-width: 102px;">Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let schedule of schedules; let i = index">
        <td>{{ schedule.start.dateTime | date }}</td>
        <td>{{ schedule.start.dateTime | date: "shortTime" }}</td>
        <td>{{ schedule.end.dateTime | date: "shortTime" }}</td>
        <td>{{ schedule.summary }}</td>
        <td>
          <button pButton type="button" label="Join Class" *ngIf="!schedule.membersAttended?.includes(firebaseService.memberId)" (click)="joinClassClick(schedule)"
            [disabled]="schedule?.viewSettings?.buttonDisabled"></button>
          <button pButton type="button" label="Leave Class" *ngIf="schedule.membersAttended?.includes(firebaseService.memberId)" (click)="leaveClassClick(schedule)" class="p-button-danger"></button>
        </td>
      </tr>
    </tbody>
  </table>
  <button pButton class="p-button-secondary" type="button" label="Done" (click)="onDoneClick()"></button>
</div>
<ng-template #loading style="margin: auto;">
  <div> No Classes Available! </div>
  <div><button pButton class="p-button-secondary" type="button" label="Done" (click)="onCloseClick()"></button></div>
</ng-template>